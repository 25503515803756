// Modal functionality
export const initModal = () => {
  const modal = document.getElementById("impressumModal");
  const impressumLink = document.querySelector('a[href="#impressum"]');
  const closeModal = document.querySelector(".close-modal");

  impressumLink.addEventListener("click", (e) => {
    e.preventDefault();
    modal.style.display = "block";
    document.body.style.overflow = "hidden";
  });

  closeModal.addEventListener("click", () => {
    modal.style.display = "none";
    document.body.style.overflow = "auto";
  });

  window.addEventListener("click", (e) => {
    if (e.target === modal) {
      modal.style.display = "none";
      document.body.style.overflow = "auto";
    }
  });
};
