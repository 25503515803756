import { initNavigation } from "./components/navigation.js";
import { initScrollReveal } from "./components/scrollReveal.js";
import { initModal } from "./components/modal.js";
import { initContactForm } from "./components/contactForm.js";

// Initialize all components
document.addEventListener("DOMContentLoaded", () => {
  initNavigation();
  initScrollReveal();
  initModal();
  initContactForm();
});
