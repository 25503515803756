// navigation.js

export const initNavigation = () => {
  const navLinks = document.querySelectorAll(".nav-link");

  // Function to handle smooth scrolling
  const smoothScroll = (target) => {
    const element = document.querySelector(target);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 70, // Adjust for header height
        behavior: "smooth",
      });
    }
  };

  // Handle navigation link clicks
  navLinks.forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      const target = link.getAttribute("href");

      // Check if it's the Impressum link
      if (target === "#impressum") {
        // Handle Impressum modal separately
        openImpressumModal();
      } else {
        smoothScroll(target);
      }
    });
  });

  // Highlight active section in navigation
  const highlightActiveSection = () => {
    const sections = document.querySelectorAll("section");
    const scrollPosition = window.scrollY;

    sections.forEach((section) => {
      const sectionTop = section.offsetTop - 100;
      const sectionBottom = sectionTop + section.offsetHeight;
      const sectionId = section.getAttribute("id");

      if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
        navLinks.forEach((link) => {
          link.classList.remove("active");
          if (link.getAttribute("href") === `#${sectionId}`) {
            link.classList.add("active");
          }
        });
      }
    });
  };

  // Listen for scroll events to highlight active section
  window.addEventListener("scroll", highlightActiveSection);

  // Initial call to highlight the correct section on page load
  highlightActiveSection();
};

// Function to open Impressum modal (implement this in your modal.js file)
const openImpressumModal = () => {
  // Your code to open the Impressum modal
  console.log("Opening Impressum modal");
};
