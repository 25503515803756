export const initContactForm = () => {
  const USER_ID = "r1Ssqc_8YsxIG4NdM";
  const SERVICE_ID = "service_qx1qigb";
  const TEMPLATE_ID = "template_ncq4wvd";

  emailjs.init(USER_ID);

  const form = document.getElementById("contactForm");
  const submitButton = form.querySelector(".submit-btn");
  const formMessage = document.getElementById("formMessage");

  const showMessage = (message, isError = false) => {
    formMessage.textContent = message;
    formMessage.className = isError ? "error-message" : "success-message";
    formMessage.style.display = "block";
    setTimeout(() => {
      formMessage.style.display = "none";
    }, 5000);
  };

  form.addEventListener("submit", async (e) => {
    e.preventDefault();
    console.log("Form submission started");

    submitButton.disabled = true;
    submitButton.textContent = "Wird gesendet...";

    const formData = {
      to_name: "Danis Hasanbegovic",
      from_name: form.querySelector("#name").value,
      from_email: form.querySelector("#email").value,
      subject: form.querySelector("#subject").value,
      message: form.querySelector("#message").value,
    };
    console.log("Sending form data:", formData);

    try {
      const result = await emailjs.send(SERVICE_ID, TEMPLATE_ID, formData);
      console.log("EmailJS response:", result);

      if (result.text === "OK") {
        console.log("Email sent successfully");
        showMessage("Vielen Dank! Ihre Nachricht wurde gesendet.");
        form.reset();
      } else {
        console.warn("Unexpected response:", result);
        showMessage(
          "Unerwartete Antwort vom E-Mail-Dienst. Bitte versuchen Sie es erneut.",
          true
        );
      }
    } catch (error) {
      console.error("Email send error:", error);
      showMessage(`Nachricht konnte nicht gesendet werden: ${error.message}`, true);
    } finally {
      submitButton.disabled = false;
      submitButton.textContent = "Send Message";
    }
  });
};
